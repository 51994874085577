<template>
  <Layout :tituloPagina="`Sistema | Roles de usuarios | Rol ${ rol.nombre } | Edición de permisos`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span class="d-none d-sm-block">
            Permisos para el rol {{ rol.nombre }}
          </span>
          <span class="d-block d-sm-none">
            Permisos del rol {{ rol.nombre }}
          </span>
        </h4>
        <div>
          <a 
            href="#"
            onclick="return false;"
            @click="$router.go(-1)"
            v-if="rol.id != null"
          >
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-nowrap" id="tabla-bloques">
            <thead>
              <tr>
                <th style="width: 200px">Bloque</th>
                <th>Descripción</th>
                <th style="width: 160px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bloque in bloques" :key="bloque.id">
                <td class="fw-semibold">{{ bloque.nombre }}</td>
                <td>{{ bloque.descripcion }}</td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'edicionPermisosRol',
                      params: { id_rol: rol.id },
                      query: { id_bloque: bloque.id }
                    }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="bx bx-list-ol"></i>
                    Editar permisos
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button class="btn btn-secondary" @click="$router.go(-1)">
        <i class="mdi mdi-chevron-left"></i>
        Atrás
      </button>
    </div>
    <br>
  </Layout>
</template>

<script>
/**
 * Funcion que muestra los bloques de funciones
 */
 import BloqueFuncionesSrv from '@/services/BloqueFuncionesSrv.js'
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
import Layout from "@/views/layouts/main"
export default {
  name: 'BloquesFunciones',
  components: { Layout },
  props: ['id_rol'],
  created: function() {
    var self = this

    // Carga del nombre del rol
    self.nombreRol = self.$route.params.nombre_rol

    // Carga del rol actualmente seleccionado
    self.cargarRol(self.$route.query.id_rol, function() {
      // Carga de bloques de funciones
      self.cargarBloquesFunciones()
    })
  },
  data: function() {
    return {
      accion: null,
      bloques: [],
      nombreRol: '',
      rol: {
        id: null,
        nombre: ''
      }
    }
  },
  methods: {
    cargarBloquesFunciones: function() {
      var self = this

      iu.spinner.mostrar('#tabla-bloques')

      BloqueFuncionesSrv.bloquesJSON().then(response => {
        self.bloques = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los bloques de funciones'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-bloques')
      })
    },

    cargarRol: function(idRol, callback) {
      var self = this
      RolUsuariosSrv.rolJSON(idRol).then(response => {
        self.rol = response.data

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el rol'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-bloques')
  }
}
</script>

<style scoped>

</style>